import React from "react";

const HeartHand = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 65C26.671 65 20.4841 63.1232 15.2218 59.607C9.95939 56.0908 5.85787 51.0931 3.43587 45.2459C1.01386 39.3986 0.380159 32.9645 1.61489 26.7571C2.84961 20.5497 5.89732 14.8479 10.3726 10.3726C14.8479 5.89732 20.5497 2.84961 26.7571 1.61489C32.9645 0.380159 39.3986 1.01386 45.2459 3.43587C51.0931 5.85787 56.0908 9.95939 59.607 15.2218C63.1232 20.4841 65 26.671 65 33C65 41.4869 61.6286 49.6263 55.6274 55.6274C49.6263 61.6286 41.4869 65 33 65ZM33 2.77779C27.0226 2.77779 21.1795 4.55029 16.2094 7.87115C11.2394 11.192 7.36577 15.9121 5.07832 21.4345C2.79087 26.9569 2.19237 33.0335 3.3585 38.8961C4.52463 44.7586 7.40302 50.1437 11.6297 54.3703C15.8563 58.597 21.2414 61.4754 27.1039 62.6415C32.9665 63.8076 39.0432 63.2091 44.5656 60.9217C50.0879 58.6342 54.808 54.7606 58.1289 49.7906C61.4497 44.8206 63.2222 38.9774 63.2222 33C63.2222 24.9846 60.0381 17.2974 54.3703 11.6297C48.7026 5.96191 41.0154 2.77779 33 2.77779Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M12.0579 45.9242H10.2801C9.40783 45.9242 8.57129 45.5777 7.9545 44.9609C7.33772 44.3441 6.99121 43.5076 6.99121 42.6353V29.2664C6.99121 28.3942 7.33772 27.5576 7.9545 26.9408C8.57129 26.324 9.40783 25.9775 10.2801 25.9775H12.0579C12.9271 25.9822 13.759 26.3308 14.372 26.9471C14.9849 27.5634 15.329 28.3972 15.329 29.2664V42.6353C15.329 43.5045 14.9849 44.3384 14.372 44.9546C13.759 45.5709 12.9271 45.9195 12.0579 45.9242ZM10.2801 27.7553C10.0817 27.7553 9.88516 27.7944 9.70182 27.8703C9.51849 27.9463 9.3519 28.0576 9.21158 28.1979C9.07126 28.3382 8.95996 28.5048 8.88402 28.6882C8.80807 28.8715 8.76899 29.068 8.76899 29.2664V42.6353C8.76899 43.0361 8.92819 43.4204 9.21158 43.7038C9.49497 43.9872 9.87933 44.1464 10.2801 44.1464H12.0579C12.4556 44.1417 12.8354 43.9805 13.1149 43.6976C13.3945 43.4147 13.5512 43.033 13.5512 42.6353V29.2664C13.5512 28.8687 13.3945 28.487 13.1149 28.2041C12.8354 27.9213 12.4556 27.76 12.0579 27.7553H10.2801Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M45.8713 51.6669C45.6356 51.6669 45.4095 51.5732 45.2428 51.4065C45.0761 51.2398 44.9824 51.0137 44.9824 50.778C44.9824 50.5422 45.0761 50.3161 45.2428 50.1494C45.4095 49.9828 45.6356 49.8891 45.8713 49.8891C46.4699 49.8374 47.0273 49.5632 47.4335 49.1204C47.8396 48.6777 48.0649 48.0988 48.0649 47.498C48.0649 46.8972 47.8396 46.3182 47.4335 45.8755C47.0273 45.4328 46.4699 45.1585 45.8713 45.1069C45.6356 45.1069 45.4095 45.0132 45.2428 44.8465C45.0761 44.6798 44.9824 44.4537 44.9824 44.218C44.9824 43.9822 45.0761 43.7561 45.2428 43.5895C45.4095 43.4228 45.6356 43.3291 45.8713 43.3291C46.9299 43.3983 47.9226 43.8676 48.6478 44.6418C49.3731 45.416 49.7767 46.4371 49.7767 47.498C49.7767 48.5589 49.3731 49.58 48.6478 50.3542C47.9226 51.1284 46.9299 51.5977 45.8713 51.6669Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M50.2446 51.6667H32.0579C30.7355 51.677 29.4608 51.1735 28.5024 50.2622L22.3335 44.2889C21.7238 43.6969 20.9077 43.3653 20.0579 43.3644H14.4402C14.2044 43.3644 13.9783 43.2708 13.8116 43.1041C13.6449 42.9374 13.5513 42.7113 13.5513 42.4756V29.8889C13.5513 29.6531 13.6449 29.427 13.8116 29.2603C13.9783 29.0936 14.2044 29 14.4402 29H24.8757C25.9026 29.0033 26.8867 29.4123 27.6135 30.1378L36.9646 39.4889C37.4603 39.9823 37.823 40.5933 38.0188 41.2647C38.2147 41.9362 38.2373 42.6463 38.0846 43.3289H50.0668C51.0865 43.3176 52.077 43.6689 52.8617 44.3202C53.6465 44.9714 54.1743 45.8802 54.3513 46.8844C54.451 47.4849 54.4184 48.0999 54.2557 48.6864C54.0929 49.2729 53.804 49.8168 53.409 50.28C53.0132 50.7262 52.5253 51.0812 51.979 51.3206C51.4327 51.56 50.841 51.6781 50.2446 51.6667ZM15.329 41.5867H20.0046C21.3284 41.5688 22.6059 42.0734 23.5602 42.9911L29.7646 49C30.3828 49.59 31.2034 49.9208 32.0579 49.9244H50.2446C50.5934 49.9176 50.9365 49.8347 51.2501 49.6817C51.5636 49.5287 51.84 49.3091 52.06 49.0383C52.28 48.7676 52.4383 48.4521 52.5239 48.1139C52.6095 47.7756 52.6204 47.4228 52.5557 47.08C52.4347 46.5065 52.1151 45.9941 51.6533 45.6331C51.1915 45.2721 50.617 45.0857 50.0313 45.1067H36.7868C36.6265 45.1091 36.4687 45.0672 36.3307 44.9856C36.1927 44.9041 36.0798 44.786 36.0046 44.6444C35.9214 44.5079 35.8773 44.351 35.8773 44.1911C35.8773 44.0312 35.9214 43.8743 36.0046 43.7378C36.2959 43.2768 36.4228 42.7309 36.3647 42.1887C36.3066 41.6465 36.0669 41.1399 35.6846 40.7511L26.3513 31.4C25.961 31.0056 25.4305 30.7819 24.8757 30.7778H15.329V41.5867Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M34.0311 46.6182C32.931 46.6327 31.8698 46.2108 31.08 45.4449L23.1511 37.516C23.0678 37.4333 23.0017 37.335 22.9565 37.2267C22.9114 37.1184 22.8882 37.0022 22.8882 36.8849C22.8882 36.7675 22.9114 36.6513 22.9565 36.543C23.0017 36.4347 23.0678 36.3364 23.1511 36.2538C23.3203 36.0898 23.5466 35.998 23.7822 35.998C24.0178 35.998 24.2442 36.0898 24.4133 36.2538L32.3244 44.1826C32.7812 44.6205 33.3895 44.865 34.0222 44.865C34.655 44.865 35.2632 44.6205 35.72 44.1826C35.8353 44.0595 35.9422 43.9289 36.04 43.7915C36.0998 43.6867 36.1803 43.5951 36.2767 43.5224C36.373 43.4497 36.4831 43.3974 36.6004 43.3687C36.7176 43.3399 36.8394 43.3353 36.9585 43.3552C37.0775 43.3751 37.1913 43.419 37.2928 43.4843C37.3943 43.5495 37.4815 43.6348 37.549 43.7348C37.6165 43.8349 37.6629 43.9476 37.6854 44.0662C37.7079 44.1848 37.706 44.3067 37.6799 44.4245C37.6537 44.5423 37.6039 44.6536 37.5333 44.7515C37.3679 45.0015 37.1772 45.2338 36.9644 45.4449C36.1801 46.2081 35.1255 46.63 34.0311 46.6182Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M41.5153 37.7292C41.2751 37.7286 41.0449 37.6327 40.8753 37.4625L30.2086 26.7958C29.5555 26.1436 29.0374 25.3691 28.6839 24.5165C28.3304 23.6639 28.1484 22.7499 28.1484 21.8269C28.1484 20.9039 28.3304 19.99 28.6839 19.1374C29.0374 18.2848 29.5555 17.5102 30.2086 16.858C31.5371 15.5604 33.3205 14.834 35.1775 14.834C37.0346 14.834 38.8179 15.5604 40.1464 16.858L41.5686 18.2625L42.9908 16.858C44.3193 15.5604 46.1027 14.834 47.9597 14.834C49.8168 14.834 51.6002 15.5604 52.9286 16.858C54.2262 18.1865 54.9527 19.9699 54.9527 21.8269C54.9527 23.684 54.2262 25.4674 52.9286 26.7958L42.1731 37.4625C42.0876 37.5497 41.9851 37.6184 41.872 37.6643C41.7588 37.7102 41.6374 37.7323 41.5153 37.7292ZM35.1508 16.538C34.126 16.5489 33.1267 16.8598 32.2766 17.4323C31.4265 18.0049 30.7628 18.814 30.3675 19.7596C29.9722 20.7053 29.8627 21.746 30.0525 22.7532C30.2422 23.7604 30.723 24.69 31.4353 25.4269L41.5153 35.5069L51.6308 25.4269C52.5974 24.4316 53.1381 23.0988 53.1381 21.7114C53.1381 20.324 52.5974 18.9912 51.6308 17.9958C50.6355 17.0293 49.3027 16.4886 47.9153 16.4886C46.5279 16.4886 45.1951 17.0293 44.1997 17.9958L42.1553 20.0403C42.0727 20.1236 41.9743 20.1897 41.866 20.2348C41.7577 20.28 41.6415 20.3032 41.5242 20.3032C41.4068 20.3032 41.2907 20.28 41.1823 20.2348C41.074 20.1897 40.9757 20.1236 40.8931 20.0403L38.8486 18.0669C38.3653 17.5785 37.7891 17.1917 37.1541 16.9291C36.5191 16.6666 35.838 16.5336 35.1508 16.538Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default HeartHand;
